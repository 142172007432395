import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import './style/main.scss'
import './permission.js'
import 'default-passive-events'
import vuescroll from 'vuescroll';
import {
  Message, MessageBox, Table, TableColumn, Empty, Tabs,
  TabPane, Image, Row, Col, Input, Button, InputNumber
} from 'element-ui';
import Vant from 'vant';
import 'vant/lib/index.css';
import moment from 'moment'
import './assets/font/font.css';

Vue.use(Vant);

Vue.use(vuescroll).use(Table).use(TableColumn).use(Empty).use(Tabs).use(TabPane).use(Image).use(Row).use(Col).use(Input).use(Button).use(InputNumber)
Vue.config.productionTip = false

Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$moment = moment;
Vue.prototype.$confirm = MessageBox.confirm;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
