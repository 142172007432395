<template>
  <div id="app">
    <router-view />
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive> -->
    <!-- <tabbar></tabbar> -->
  </div>
</template>

<script>
// import Tabbar from '@/components/Tabbar'
export default {
  // components: {
  //   Tabbar
  // }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
// body {
//   // width: 375px;
// }
</style>
