import router from './router'
import moment from 'moment'
const nowTime = moment().format('YYYY-MM-DD HH:ss:mm')
const startTime = '2022-10-15 14:30:00'
const endTime = '2022-10-24 08:00:00'
router.beforeEach((to, from, next) => {
  if (Object.is(to.name, 'maintain')) {
    next();
    return
  }
  if (moment(nowTime).diff(moment(startTime), 'seconds') > 0 && moment(nowTime).diff(moment(endTime), 'seconds') <= 0) {
    next('/maintain')
  } else {
    next()
  }
})

router.afterEach(() => {
  // NProgress.done()
})
